import { Link } from 'gatsby';
import { StaticImage } from 'gatsby-plugin-image';
import React, { useState, useEffect, useCallback } from 'react'
import "./sections.css"
import { API_BASE_URL } from "../../constants/config"
const Section1 = (props) => {
    const [selected, setSelected] = useState("");
    return <div className="contact contact-sec-1">
        <div className="content">
            <div className="inner">
                <h1>Contact us</h1>
                <h2>Let us help you get the information you need.</h2>
                <span className="iwant">I want to:</span>
                <div className="select-input">
                    <select className={(selected !== '' ? 'active' : '')} onChange={(e) => { setSelected(e.target.value); props.onSelectOption(e.target.value) }}>
                        <option value="">&mdash; Select One &mdash;</option>
                        <option value="surrender">Surrender My Retriever</option>
                        <option value="adopt">Adopt a Labrador Retriever</option>
                        <option value="foster">Foster a Labrador Retriever</option>
                        <option value="volunteer">Volunteer for a great organization</option>
                        <option value="available">Check the Available Labs</option>
                        <option value="coming-soon">Check the Coming Soon Labs</option>
                        <option value="get-notify">Want to notify the Rescue of an available Purebred Labrador Retriever?</option>
                        <option value="ask-a-question">Ask a question about your adoption application</option>
                    </select>
                </div>
            </div>
        </div>
    </div>
}
const Section2 = () => {
    return <div className='contact contact-sec-2 bg-gray'>
        <div className='content'>
            <div className='inner'>
                <h3>SURRENDER</h3>
                <div className='intro'>
                    <h4>So…you want to surrender your Labrador to Dallas Fort Worth Labrador Retriever Rescue Club? <strong>Please reconsider – their life is in your hands!</strong></h4>
                </div>
                <p>The reality is – alternatives and solutions exist for many of the behaviors, health concerns, and time constraints that generally lead people to consider surrendering their Labrador. Your Labrador is a member of your family and you owe it to them to exhaust all other means before deciding to give them up. Their life is very much in your hands.</p>
                <p>Before you make the decision to surrender your Labrador, we encourage you to read <Link to="http://www.wonderpuppy.net/canwehelp/index.html" target="_blank">Can We Help You Keep Your Pet?</Link> On these pages, you will hopefully find some advice and piece of mind to help you keep your Labrador. There is also a lot of information for those who would never dream of giving up their pet, but just need some advice on basic pet problems. It only takes a few minutes of your time and you may be very surprised at what you read.</p>
                <div className='surrender'>
                    <h4>How to Surrender</h4>
                    <p>If you have read this far and feel that you have no other choice but to surrender your Labrador to rescue, DFWLRRC may be able to help.</p>
                </div>
                <ol>
                    <li>Download the <Link target="_blank" to={API_BASE_URL + "/website/downloads/download-surrender-information-form"}>Surrender Information Form</Link></li>
                    <li>Send completed form &amp; photographs of your Lab to: <a href="mailto:surrender@dfwlabrescue.org">surrender@dfwlabrescue.org</a>. These photographs must be of high quality, not blurry, and include a close-up face-shot and another full-view of the Labrador. Your surrender information will not be considered until adequate photographs have been provided to the team for review.  Along with the pictures, include your name, address, contact phone number, and add the following to the subject line of the email:  “Surrender Application Information”.</li>
                </ol>
                <p><strong>Please Note:</strong>&nbsp;This form is only the first step in the process of having your Labrador considered by our Incoming Team and is NOT a commitment from DFWLRRC to accept your pet into our rescue program.&nbsp;<strong>The surrender process can take SEVERAL WEEKS, and generally will NOT happen “overnight”.</strong></p>
                <p>All Labradors under consideration by DFWLRRC are evaluated for temperament, current health status and whether or not it’s a pure-bred Labrador (AKC papers not required, but the dog should meet&nbsp;<Link to="https://en.wikipedia.org/wiki/Labrador_Retriever"><strong>official breed standards in appearance and temperament</strong></Link>). In most circumstances we require that the dog be current on all vaccinations, on heartworm preventative, spayed or neutered and a $100.00 re-homing fee.</p>
                <p><strong>Important: We do not accept vicious or dangerous dogs as defined by the Texas Legal Code; nor do we accept dogs that have bitten humans, dogs, or other animals.</strong></p>
            </div>
        </div>
    </div>
}

const Section3 = () => {
    return <div className='contact contact-sec-3 bg-gray'>
        <div className='content'>
            <div className='inner'>
                <h3>ADOPTING A LAB</h3>
                <h4 className='lg'>The Process…</h4>
                <h4>Overview of our adoption process:</h4>
                <ul>
                    <li>A potential adopter completes the adoption application.&nbsp;DFWLRRC has the right to deny or refuse any application.</li>
                    <li>The Applications Team reviews the application and if approved, the applicant is scheduled for a home visit by one of our volunteers.</li>
                    <li>Once the home visit is complete, the Applications Team forwards application to the Director of Adoptions who will determine if the application is approved.</li>
                    <li>The approved adopter is then contacted by the Director of Adoptions and informed about the matching process.&nbsp; They are given the contact information for the Adoptions Team and encouraged to provide a list of Labs they are interested in meeting.</li>
                    <li>If no Labs are requested, every effort will be made to provide matches with available Labs.</li>
                    <li>Following a match, the approved adopter contacts the foster family to learn more about the matched Lab and can arrange a time to meet the Lab, and if necessary, meet existing family animals.</li>
                    <li>Once a dog is agreed upon, the adoption process is completed when the new family signs the adoption contract, pays the adoption donation, and has secured the necessary items for the new family pet (collar, leash, ID tag, crate, etc.)</li>
                    <li>Potential adopters are welcome to attend any Meet and Greets.</li>
                </ul>
                <p>On average, it can take between one and five months to complete the process of applying, having a home visit done, meeting potential Labs and adopting a Rescue Lab, depending upon your personal circumstances and flexibility, the number of families waiting to adopt, and the number of dogs available for adoption.</p>
                <h4>Adoption Fees:</h4>
                <p className='italic'>Effective Jan 1, 2025:</p>
                <ul>
                    <li>$425 puppy adoption (&lt; 6 months old)</li>
                    <li>$350 adult adoption (6 months - 7+ years old)</li>
                    <li>$250 senior adoption (8+ years old)</li>
                </ul>
                <p>The adoption fee helps us meet the boarding and veterinary expenses incurred by our non-profit organization, although our average expense per Lab is still far greater than the adoption fee. </p>

                <p>Remember, DFWLRRC has the right to deny or refuse any application.</p>
                <h4>Overview of our rescue process:</h4>

                <ul>
                    <li>Once accepted into our program all Labs get a trip to our veterinarian for complete checkups, required immunizations, heartworm tests/treatments, spaying or neutering (if necessary), and any other immediate medical treatments needed.</li>
                    <li>Once in a foster home, the rescue Lab is introduced to a warm, loving, safe environment.  Depending on the dog and the foster, he/she may be crate trained, receive some basic obedience training, and complete any necessary follow-up care prescribed by our vets.  Our rescue Labs are treated like members of the family and receive all the spoils a Lab deserves.</li>
                    <li>The minimum stay in a foster home is 12 days.  This time helps the foster home to get to know the Lab and to make a good decision when making an adoption.</li>
                </ul>

                <h4>Requirements of a Forever Home:</h4>

                <ul>
                    <li>The dog will not be kept outdoors during the adopter’s working hours, or at any other time left alone outdoors while the adopter is not at home. The dog may be confined to a room such as a bedroom or basement, or any other room of adequate size, and protected from the elements of weather</li>
                    <li>Yard is securely fenced (Adoptions to apartment dwellers depend on the dog requested).</li>
                    <li>
                    Forever home is in one of the following DFW and nearby counties.<br/><br/>

Our Core Area of coverage is Dallas, Denton, Collin and Tarrant Counties.<br/><br/>

In addition to our Core Area: We are currently accepting Adoption Applications from an Extended Area that includes: Ellis, Grayson, Hood, Hunt, Johnson, Parker, Rockwall, Somervell and Wise Counties.<br/><br/>

Please Note: In order to expand to these new counties, applicants from Extended Area counties will be responsible for driving to the Foster homes in our Core Area counties to meet dogs they have been matched to.
                    </li>
                    <li>No children under the age of 6 unless they have been raised with large dogs.</li>
                    <li>Has an adult who wants to train and care for the dog </li>
                    <li>Existing and recent pets must be spayed/neutered, on heartworm preventative, and current on all recommended vaccinations. (Please specify if there is a medical reason any of these conditions are not met.)</li>
                    <li>Questions about these requirements can be sent to <a href="mailto:applications@dfwlabrescue.org">applications@dfwlabrescue.org</a>.</li>
                </ul>
                <p><strong>To start the adoption application, <Link to="/adoption-process/">click here</Link>.</strong></p>
            </div>
        </div>
    </div>
}
const Section4 = () => {
    return <div className='contact contact-sec-4 bg-gray'>
        <div className='content'>
            <div className='inner'>
                <h3>WE NEED FOSTER HOMES!</h3>
                <div className='intro'>
                    <p>Become a foster now! Fill out our application or download the <Link to={API_BASE_URL + "/website/downloads/download-foster-application-form"}>Foster Application Form</Link> and email to <a href="mailto:foster@dfwlabrescue.org">foster@dfwlabrescue.org</a></p>
                </div>
                <h4 className='lg'>Fostering</h4>
                <p>One of the most vital parts of our organization are our foster families. Because DFWLRRC does not have a shelter facility, every animal that comes into our organization must have a foster home until it is adopted out. The more foster homes we have, the more deserving Labs we can save. It’s that simple. Think you could never foster? Think again and discover one of the most rewarding experiences you might ever be a part of.</p>
                <div className='img1'>
                    <StaticImage layout="constrained" src='../../images/fosters-wanted-sept-2015.jpg' />
                </div>
                <h4 className='lg'>Requirements</h4>
                <p>All DFWLRRC foster families must be able to provide the following:</p>
                <ul>
                    <li>A safe, secure, and loving home</li>
                    <li>The necessary time to work with dogs to provide basic training and play time</li>
                    <li>Transportation to necessary veterinary appointments and Meet and Greets</li>
                </ul>
                <h4 className='lg'>Fostering FAQs</h4>
                <p><strong>Can I choose the dog I want?</strong><br />
                    Absolutely! When you apply to become a foster and are in our system, you may specify the age, sex, and activity level of the foster dog you would prefer. Whatever kind of dog you choose, you can guarantee they will be grateful that you opened your home to them.</p>
                <p><strong>What is my financial responsibility?</strong><br />
                    Other than the cost of food, there is none. DFWLRRC will provide any necessary materials, crates and will cover the veterinary expenses of all dogs.</p>
                <p><strong>What if I already have a dog (or cat, hamster, etc)?</strong><br />
                    No problem! Most of our foster families have their own pets and find that foster dogs slide right into their environment. In fact, well-balanced family pets can often have great positive impacts on our dogs. They can be wonderful confidence builders and help show the new dog “the ropes” of how great it is to be in a caring and stable environment.</p>
                <p><strong>How long will I have my foster dog?</strong><br />
                    Typically, we require that most dogs stay with their foster families for a minimum of 12 days. This helps the dog get settled a bit and helps the foster family get to know the dog’s personality and figure out the best type of forever family for him or her. The length of time can vary depending on the dog. Some of our dogs have health and/or behavioral issues that require a longer stay in foster care.</p>
                <p><strong>You have my attention. What happens next?</strong><br />
                    You can fill out our foster application here. Once our foster team has received your application, one of our volunteers will be in touch with you to schedule a home study. During the home study, a volunteer will look at your property to confirm that you have a secure fence and the right environment for keeping dogs. It also gives potential volunteers a great opportunity to discuss fostering with one of our experienced volunteers. Once your home study has been conducted you will be assigned a mentor volunteer. This volunteer will be your key contact for any questions regarding the dog, attendance of Meet n Greets, veterinary visits, and any other questions you may have. At this point you will be matched with the appropriate dog and you’ll be ready to go.</p>
                <p><strong>But I could never foster, it would be too hard to say goodbye.</strong><br />
                    Yes, saying goodbye to a foster dog is tough. Sometimes, it’s really, really tough. But that difficulty is no match for the joy you will feel when seeing your foster dog find that absolutely perfect family for them to spend the rest of their life with. It is bittersweet, but the good far outweighs the bad. Many of our foster families keep in regular touch with their foster dogs’ forever families after they have been adopted.</p>
                <p><strong>If you have any more questions regarding fostering for DFWLRRC, please feel free to send us an email.</strong><br />
                    Please don’t delay…Labs in need are waiting for you! Give a Lab a New Leash on Life! To become a foster-care provider, please click here to complete an application.</p>
                <p>Still not sure, follow <a href="https://www.facebook.com/DFWLabradorRescue" target="_blank">DFW Lab Rescue</a> on Facebook and keep in touch.</p>
                <div className='img1'>
                    <StaticImage layout="constrained" src='../../images/weneedfosters-injured.jpg' />
                </div>
                <div className='top-10'>
                    <h4 className='lg'>Top 10 Reasons To Foster</h4>
                    <div className='img2'>
                        <StaticImage layout="constrained" src='../../images/top10-pics.png' />
                    </div>
                    <ol>
                        <li>You meet GREAT people who share your passion and love for Labs</li>
                        <li>A family affair fostering a Lab allows your entire family to participate in a volunteer activity.</li>
                        <li>A foster Lab will kiss you…even when you have morning breath.</li>
                        <li>There is nothing better than puppy breath except maybe puppy kisses)!</li>
                        <li>Companionship without a lifetime commitment - You can foster one Lab. It can be just a few days, a few weeks or a few months. It can be as long or as short as you like.</li>
                        <li>Emotionally Rewarding - To see the light and life come back into an abandoned Lab’s eyes, and know that YOU helped put it there!</li>
                        <li>Fostering is proof that God does exist!</li>
                        <li>Saving a Lab wont change the world, but for that one Lab, their world will change forever. Who couldn’t use a little more unconditional love?</li>
                        <li>Pure Joy - The memories that warm your heart and touch your soul…Christmas card updates…pictures and letters from all our adopted families.</li>
                        <li>Because for every one Lab you agree to foster, there are ten others who will never get that opportunity.</li>
                    </ol>
                </div>
            </div>
        </div>
    </div>
}
const Section5 = () => {
    return <div className='contact contact-sec-5 bg-gray'>
        <div className='content'>
            <div className='inner'>
                <h3>VOLUNTEERING</h3>
                <h4 className='lg'>Volunteer Information</h4>
                <div className='img3'>
                    <StaticImage layout="constrained" src="../../images/vacancyad1.jpg" />
                </div>
                <p className='center'>Click <Link to="https://mailchi.mp/dwlabrescue/volunteers" target="_blank">here</Link> for more information.</p>
                <p>Volunteers, they are the lifeline for each and every lab that comes through DFWLRRC. Just look into the eyes of any of the rescues and you will see their eternal gratitude for that second chance at life…the second chance our volunteers gave to them. They are so thankful a volunteer listened to that inner voice that nudged them into signing up to do that home study, showing up to that meet n greet to talk with potential adopters or opening up their home to foster a Lab in need. Yes, each and every one of our volunteers makes a difference — no matter what the contribution. Without our volunteers we would not have been able to help over 2500 Labs since 2004.</p>
                <p>We have some of the best volunteers around! Still, we struggle to meet the demands with all the Labs that end up in shelters each and every week. The numbers are truly staggering, and the work to be done is never ending. What can you do to help?</p>
                <p>While fostering is our greatest need, it isn’t for everyone. If you cannot foster, there are still so many ways you can help:<br />
                </p>
                <ul>
                    <li>Attend a Meet n Greet – These are the events where our foster dogs meet potential adopters, a step towards finding their forever homes. We are always in need of volunteers to transport and hold fosters, set up and break down the booth, take the dogs on potty breaks, fill water bowls and talk to potential adopters. We typically hold one to two of these events on the weekends. Each event requires at least 3-5 dogs and 5-10 volunteers. If you have a free time on a Saturday afternoon and can help, please let us know.</li>
                    <li>Participate in a Special Event – We take advantage of several special event opportunities throughout the year. These events help us raise funds for our foster dogs which helps cover their medical expenses. They not only benefit the Labs, they are also fun. We have recently participated in Wrapping for Retreivers, Washes and Wags and the Byron Nelson. We also host our annual Run for Retrievers and the Sporting Chance Golf Tournament.</li>
                    <li>Host a Meet n Greet – In addition to the Meet n Greets we routinely hold, we are open to hosting in new locations where we can expand our reach. Do you know of a great new location in a pet store, at a neighborhood function such as a vendor fair or at a special event that you would like to host? If so, let us know. We have had recent success setting up in these types of locations and we are open to considering others.</li>
                    <li>Volunteer to Perform Home Studies in your Area – We receive applications from potential adopters from all over the Metroplex. Occasionally we do not have adequate coverage for some of the areas. This can result in delayed home studies for some applicants, something we want to avoid. If this interests you, please let us know. We can provide training with a seasoned volunteer until you are ready to do a few on your own.</li>
                    <li>Serve on a Committee or Serve as an Event Coordinator – We routinely post requests for committee members or event coordinators. These opportunities can range from fundraising to coordinating the volunteers, schedules and activities at special events such as our Run for Retrievers, the Sporting Chance Golf Tournament, or the Byron Nelson. These are fun ways to get involved and meet fellow volunteers.</li>
                </ul>
                <p>Do you have others ideas or want to help but have a limited schedule? If so, just let us know what interests you and how much time you have. We can find the perfect volunteer opportunity to fit your schedule. Even volunteering an afternoon or two every once in a while can make all the difference for a lab in need.</p>
                <p><strong>Give a deserving Lab a “new leash on life” by becoming a volunteer. <a href="mailto:volunteer@dfwlabrescue.org">Contact Us</a> for more information or fill out the <Link to="/application/volunteer">Volunteer Form</Link>.</strong></p>
            </div>
        </div>
    </div>
}

const Section6 = () => {
    return <div className='contact contact-sec-6 bg-gray'>
        <div className='content'>
            <div className='inner'>
                <p>Send an email to <a href="mailto:Incoming@dfwlabrescue.org">Incoming@dfwlabrescue.org</a>.</p>
            </div>
        </div>
    </div>
}

const Section7 = () => {
    return <div className='contact contact-sec-7 bg-gray'>
        <div className='content'>
            <div className='inner'>
                <p>Send an email to our <a href="mailto:applications@dfwlabrescue.org">Application Team</a>.</p>
            </div>
        </div>
    </div>
}
const SideBar = () => {
    const onScroll = useCallback(() => {

        if (window.innerWidth >= 1024) {
            var e = document.querySelector('.contact-sidebar'),
                p = (document.documentElement.scrollTop || document.body.scrollTop) / ((document.documentElement.scrollHeight || document.body.scrollHeight) - document.documentElement.clientHeight);
            e.scrollTop = p * e.scrollHeight;
        }
    }, [])

    useEffect(() => {
        var m = true;
        if (m) {
            window.addEventListener("scroll", onScroll);
        }
        return () => {
            m = false;
            window.removeEventListener("scroll", onScroll);
        }
    }, [onScroll])

    return <div className="contact-sidebar">
        <div className='content'>
            <h4>GENERAL QUESTIONS</h4>
            <a href="mailto:Info@dfwlabrescue.org" className="lg">info@dfwlabrescue.org</a>
            <h4>BOARD OF DIRECTORS</h4>
            <a href="mailto:Directors@dfwlabrescue.org" className="lg">directors@dfwlabrescue.org</a>
            <h4>BOARD MEMBERS</h4>
            <div className='boards'>
                <p>Executive Director - <a href="mailto:executivedirector@dfwlabrescue.org">executivedirector@dfwlabrescue.org</a></p>
                <p>Director of Adoptions - <a href="mailto:adoptions@dfwlabrescue.org">adoptions@dfwlabrescue.org</a></p>
                <p>Director of Applications - <a href="mailto:applications@dfwlabrescue.org">applications@dfwlabrescue.org</a></p>
                <p>Director of Communications - <a href="mailto:communications@dfwlabrescue.org">communications@dfwlabrescue.org</a></p>
                <p>Director of Finance - <a href="mailto:treasurer@dfwlabrescue.org">treasurer@dfwlabrescue.org</a></p>
                <p>Director of Foster Relations - <a href="mailto:foster@dfwlabrescue.org">foster@dfwlabrescue.org</a></p>
                <p>Director of Fundraising - <a href="mailto:fundraising@dfwlabrescue.org">fundraising@dfwlabrescue.org</a></p>
                <p>Director of Incoming - <a href="mailto:incoming@dfwlabrescue.org">incoming@dfwlabrescue.org</a></p>
                <p>Director of Membership - <a href="mailto:volunteer@dfwlabrescue.org">volunteer@dfwlabrescue.org</a></p>
            </div>
            <div className='mail'>
                <h4>MAIL</h4>
                <p>
                    <strong>
                        Dallas Fort Worth Labrador Retriever Rescue Club, Inc.<br />
                        P.O. Box 94002<br />
                        Southlake, TX 76092
                    </strong>
                </p>
            </div>
            <p className='note'>NOTE:  We are not located at this address.</p>
            <p className='phone'><strong>PHONE</strong> <Link to="tel:469.213.0271">469.213.0271</Link></p>
            <p className='note'>Please leave a voicemail – this is the slowest method of contacting us.  Voice messages about surrendering a Lab or to alert us about an available Lab will not be returned.  See above for surrender information and how to contact us about an available Lab.</p>
        </div>
    </div>
}

const Sections = {
    Section1,
    Section2,
    Section3,
    Section4,
    Section5,
    Section6,
    Section7,
    SideBar
}

export default Sections